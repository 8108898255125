import { Col, Row, Typography } from "antd";
import { DisableListTotalsDto } from '../../api/client';

interface IProps {
  disabledTotals: DisableListTotalsDto

  maxSlots: number
  maxOverlap: number
  waLimit: number
  haLimit: number
  wgLimit: number
  hgLimit: number

  totalSlots: number
  totalOverlap: number
}

const Summary = ({ disabledTotals, maxSlots, maxOverlap, waLimit, haLimit, wgLimit, hgLimit, totalSlots, totalOverlap } : IProps) => {
  const { Title, Paragraph } = Typography;

  return (
    <Row>
      <Col span={24}>
        <Title level={3}>
          Disablelist
        </Title>
      </Col>
      <Col span={24}>
        <Paragraph>
          Slots: <span className={maxSlots && totalSlots > maxSlots ? 'overlimit-error' : ''}>{totalSlots}</span>{maxSlots ? `/${maxSlots}` : ''}
          , Overlap: <span className={maxOverlap && totalOverlap > maxOverlap ? 'overlimit-error' : ''}>{totalOverlap}</span>{maxOverlap ? `/${maxOverlap}` : ''}
        </Paragraph>
        <Paragraph>
          {disabledTotals?.totalCharacters || 0} disabled, (
          <span className={(disabledTotals?.totalWa || 0) > waLimit ? 'overlimit-warn' : ''}>{disabledTotals?.totalWa || 0}</span>/{waLimit} $wa,&nbsp;
          <span className={(disabledTotals?.totalHa || 0) > haLimit ? 'overlimit-warn' : ''}>{disabledTotals?.totalHa || 0}</span>/{haLimit} $ha,&nbsp;
          <span className={(disabledTotals?.totalWg || 0) > wgLimit ? 'overlimit-warn' : ''}>{disabledTotals?.totalWg || 0}</span>/{wgLimit} $wg,&nbsp;
          <span className={(disabledTotals?.totalHg || 0) > hgLimit ? 'overlimit-warn' : ''}>{disabledTotals?.totalHg || 0}</span>/{hgLimit} $hg)
        </Paragraph>
      </Col>
    </Row>
  )
};

export default Summary;
