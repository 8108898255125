import { Col, Form, Input, Row } from "antd";

const ResultKeys = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="starWishKeys" label="Starwish Keys">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="ownedWishKeys" label="Owned Wish Keys">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="ownedNormalKeys" label="Owned Non-Wish Keys">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
    </Row>
  )
};

export default ResultKeys;
