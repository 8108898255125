import { Helmet } from 'react-helmet-async';

interface IProps {
  title: string
  description: string
}

const Seo = ({ title, description } : IProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
};

export default Seo;
