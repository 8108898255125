import './formatList.scss';
import '../shared.scss';

import { Button, Checkbox, Col, Collapse, Form, Input, InputNumber, Row, Select, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { IFormatListForm, IMessageGroup } from './types';
import TextArea from 'antd/es/input/TextArea';
import { UtilityApi } from '../../api/clientFactory';
import { FormatListInput, GroupDto, GroupMethod } from '../../api/client';
import { buildMessages, getLimit } from '../shared/messageHelper';
import ResultGroup from './resultGroup';
import Seo from '../shared/seo';

function FormatList() {
  const { Title, Paragraph } = Typography;
  const { Panel } = Collapse;
  const { Option } = Select;

  const [form] = Form.useForm<IFormatListForm>();
  const [groupMethod, setGroupMethod] = useState<GroupMethod>(GroupMethod.None);

  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [itemCount, setItemCount] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [messageGroups, setMessageGroups] = useState<IMessageGroup[]>([]);

  useEffect(() => {
    form.setFieldValue('isNitroLimit', JSON.parse(localStorage.getItem('isNitro') || 'false'));
    form.setFieldValue('groupMethod', GroupMethod.None);
  }, [form]);

  const handleNitroToggle = useCallback((isNitro: boolean) => {
    localStorage.setItem('isNitro', JSON.stringify(isNitro));
  }, [])

  const createMessageGroups = useCallback((groups: GroupDto[], hasGroupSuffix: boolean) => {
    let prefix = form.getFieldValue('prefix');
    if (prefix) {
      prefix = prefix.trim() + ' ';
    } else {
      prefix = '';
    }

    let suffix = form.getFieldValue('suffix');
    if (!suffix) {
      suffix = ''
    }

    let limit = getLimit(form.getFieldValue('isNitroLimit'), prefix);

    return groups.map(g => { 
      let groupSuffix = suffix;
      if (hasGroupSuffix) {
        groupSuffix += `$${g.key || ''}`
      }

      return { key: g.key || '', isGroup: g.isGroup || false, messages: buildMessages(g.items || [], limit, prefix, groupSuffix, '$') }; 
    });
  }, [form]);

  const format = useCallback(() => {
    form.validateFields();

    let input = new FormatListInput();

    input.maxCount = form.getFieldValue('maxItemCount');
    input.text = form.getFieldValue('rawText');

    input.itemPrefix = form.getFieldValue('itemPrefix');
    input.itemSuffix = form.getFieldValue('itemSuffix');
    input.minRank = form.getFieldValue('minRank');
    input.maxRank = form.getFieldValue('maxRank');
    input.minKeys = form.getFieldValue('minKeys');
    input.maxKeys = form.getFieldValue('maxKeys');
    input.minSoulkeys = form.getFieldValue('minSoulkeys');
    input.maxSoulkeys = form.getFieldValue('maxSoulkeys');
    input.groupMethod = form.getFieldValue('groupMethod');

    let hasGroupSuffix = input.groupMethod != GroupMethod.None && form.getFieldValue('hasGroupSuffix');

    UtilityApi
      .formatList(input)
      .then(response => {
        setTotalItemCount(response.totalItemCount || 0);
        setItemCount(response.itemCount || 0);

        let groups = createMessageGroups(response.groups || [], hasGroupSuffix) || []
        setMessageCount(groups.reduce((a, b) => { return a + b.messages.length; }, 0));
        setMessageGroups(groups);
      });
  }, [form, setTotalItemCount, setItemCount, setMessageGroups, createMessageGroups]);

  return (
    <div className='format-list'>
      <Seo title='Mudae List Formatter' description='Format a list of characters or pins into a Mudae friendly $ separated list'/>
      <Title>
        List formatter
      </Title>
      <Title level={2}>
        Notes
      </Title>
      <Paragraph>
        <ul>
          <li>
            Please copy your text directly from Mudae
          </li>
          <li>
            Removes flag data (r, l, k, y, o, etc.)
          </li>
          <li>
            Auto splits into 2000 (4000 with nitro) length messages
          </li>
        </ul>
      </Paragraph>

      <Form form={form} layout="vertical">
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item 
              name="prefix" 
              label={
                <Tooltip title="Command your plan on using">
                  <span>Prefix/Command</span>
                </Tooltip>
              }>
              <Input placeholder='$wish'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="suffix" 
              label={
                <Tooltip title="Additional text you want at the start of each message">
                  <span>Suffix</span>
                </Tooltip>
              }>
              <Input placeholder='$Some note'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="maxItemCount" 
              label={
                <Tooltip title="Maximum number of items to take from the list">
                  <span>Max Item Count</span>
                </Tooltip>
              }>
              <InputNumber className='number-input' min={0} precision={0}/>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="isNitroLimit" valuePropName="checked">
              <Checkbox onChange={(e) => handleNitroToggle(e.target.checked)}>Nitro Character Limit</Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Collapse bordered={false} className='options-collapse'>
              <Panel header='Advanced Options' key='1'>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item 
                      name="itemPrefix" 
                      label={
                        <Tooltip title="Additional text you want before each item">
                          <span>Item Prefix</span>
                        </Tooltip>
                      }>
                      <Input placeholder='--'/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="itemSuffix" 
                      label={
                        <Tooltip title="Additional text you want after each item">
                          <span>Item Suffix</span>
                        </Tooltip>
                      }>
                      <Input placeholder='--'/>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Paragraph>* For these options to work correctly, the relevant data must be included with your raw text</Paragraph>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      name="minRank" 
                      label={
                        <Tooltip title="Minimum rank to filter for">
                          <span>Min Rank</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="maxRank" 
                      label={
                        <Tooltip title="Maximum rank to filter for">
                          <span>Max Rank</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="minKeys" 
                      label={
                        <Tooltip title="Minimum number of keys to filter for">
                          <span>Min Keys</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="maxKeys" 
                      label={
                        <Tooltip title="Maximum number of keys to filter for">
                          <span>Max Keys</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="minSoulkeys" 
                      label={
                        <Tooltip title="Minimum number of soulkeys to filter for">
                          <span>Min Soulkeys</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="maxSoulkeys" 
                      label={
                        <Tooltip title="Maximum number of soulkeys to filter for">
                          <span>Max Soulkeys</span>
                        </Tooltip>
                      }>
                      <InputNumber className='number-input' min={1} precision={0}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      name="groupMethod" 
                      label={
                        <Tooltip title="How to group results">
                          <span>Group By</span>
                        </Tooltip>
                      }>
                        <Select onChange={(value, option) => setGroupMethod(value)}>
                          <Option value={GroupMethod.None}>No grouping</Option>
                          <Option value={GroupMethod.Owner}>Owner</Option>
                          <Option value={GroupMethod.Note}>Note</Option>
                        </Select>
                    </Form.Item>
                    {
                      groupMethod != GroupMethod.None && (
                      <Form.Item name="hasGroupSuffix" valuePropName="checked">
                        <Checkbox>Add Group as Suffix</Checkbox>
                      </Form.Item>
                      )
                    }
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>

          <Col span={24}>
            <Form.Item 
              name="rawText" 
              label={
                <Tooltip title="List of characters, series, or bundles, or pins copied from mudae">
                  <span>Please enter your raw text (one item per line)</span>
                </Tooltip>
              }
              rules={[{ required: true, message: "Text is required" }]}>
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
          
        <Row className='button-row'>
          <Col>
            <Button onClick={format}>
              Format
            </Button>
          </Col>
        </Row>

        {itemCount > 0 && (
          <Row className='message-list'>
            <Col span={24}>{itemCount}{itemCount < totalItemCount ? ` of ${totalItemCount}` : ''} item{itemCount > 1 ? 's' : ''} split into {messageCount} message{messageCount > 1 ? 's' : ''}</Col>
            
            {messageGroups.map((value, index) =>
              <ResultGroup key={index} index={index} group={value}></ResultGroup>
            )}
          </Row>
        )}
      </Form>
    </div>
  );
}

export default FormatList;
