import './loots.scss';
import '../shared.scss';

import { Button, Col, Form, Input, InputNumber, Row, Select, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { KakeraLootsCalculateResult, KakeraLootsCalculationMode, KakeraLootsInput } from '../../api/client';
import { KakeraLootsApi } from '../../api/clientFactory';
import ResultLoots from './resultLoots';
import Seo from '../shared/seo';

function Loots() {
  const { Title, Paragraph } = Typography;
  const { Option } = Select;

  const [form] = Form.useForm<KakeraLootsInput>();
  const [resultForm] = Form.useForm<KakeraLootsCalculateResult>();

  const [targetTitle, setTargetTitle] = useState<string>();
  const [targetMax, setTargetMax] = useState<number>();

  const [resultMode, setResultMode] = useState(-1);

  useEffect(() => {
    form.setFieldValue('mode', KakeraLootsCalculationMode.Estimation);
    updateTarget(KakeraLootsCalculationMode.Estimation);
  }, [form]);

  const updateTarget = useCallback((calcMode: KakeraLootsCalculationMode) => {
    var max = 0;

    switch (calcMode) {
      case KakeraLootsCalculationMode.Estimation:
        setTargetTitle('Kl Count');
        max = 2147483647;
        break;
      case KakeraLootsCalculationMode.TargetRolls:
        setTargetTitle('Target Rolls');
        max = 13500;
        break;
      case KakeraLootsCalculationMode.TargetWishes:
        setTargetTitle('Target Wishlist Slots');
        max = 19000;
        break;
      case KakeraLootsCalculationMode.TargetWishProtect:
        setTargetTitle('Target Wishprotect Level');
        max = 1006575448;
        break;
      case KakeraLootsCalculationMode.TargetOverlap:
        setTargetTitle('Target Disablelist Overlap');
        max = 5250000;
        break;
      case KakeraLootsCalculationMode.TargetLimroulA:
        setTargetTitle('Target Animanga Limroul');
        max = 950000;
        break;
      case KakeraLootsCalculationMode.TargetLimroulG:
        setTargetTitle('Target Game Limroul');
        max = 625000;
        break;
    }

    if (form.getFieldValue('target') > max)
    {
      form.setFieldValue('target', max);
    }
    setTargetMax(max);
  }, [form, setTargetTitle, setTargetMax]);

  const calculate = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        KakeraLootsApi
          .calculate(values)
          .then((response) => {
            resultForm.setFieldsValue(response);
            setResultMode(form.getFieldValue('mode'));
          });
      });
  }, [KakeraLootsApi, form, setResultMode]);

  return (
    <div className='loots'>
      <Seo title='Mudae Kakera Loots Calculator' description='Calculate the expected rewards from doing Mudae kakera loots'/>
      <Title>
        Approximate Kakera Loots Calculator
      </Title>
      <Title level={2}>
        Notes
      </Title>
      <Paragraph>
        <ul>
          <li>
            Due to the complexity of kakera loots, this calulator is not 100% accurate and may be off (especially at kl counts below 1k)
          </li>
          <li>
            This calculator only works with Quality 100 and Quantity 100
          </li>
          <li>
            Because Loots are RNG your actual results may vary by a few % (at lower kl counts the expected variance will be larger as well)
          </li>
        </ul>
      </Paragraph>

      <Form form={form} layout="vertical">
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item 
              name="mode" 
              label={
                <Tooltip title="Calculation to perform">
                  <span>Calculation Mode</span>
                </Tooltip>
              }
              rules={[{ required: true, message: "Calculation Mode is required" }]}>
              <Select onChange={updateTarget}>
                <Option value={KakeraLootsCalculationMode.Estimation}>Estimate rewards from Kl Count</Option>
                <Option value={KakeraLootsCalculationMode.TargetRolls}>Estimate Kl count needed for # of rolls</Option>
                <Option value={KakeraLootsCalculationMode.TargetWishes}>Estimate Kl count needed for # of wishlist slots</Option>
                <Option value={KakeraLootsCalculationMode.TargetWishProtect}>Estimate Kl count needed for wishprotect level</Option>
                <Option value={KakeraLootsCalculationMode.TargetOverlap}>Estimate Kl count needed for # of disablelist overlap</Option>
                <Option value={KakeraLootsCalculationMode.TargetLimroulA}>Estimate Kl count needed for # of animanga limroul</Option>
                <Option value={KakeraLootsCalculationMode.TargetLimroulG}>Estimate Kl count needed for # of game limroul</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item 
              name="target" 
              label={
                <Tooltip title={`Target ${targetTitle}`}>
                  <span>{targetTitle}</span>
                </Tooltip>
              }
              rules={[{ required: true, message: `${targetTitle} is required` }]}>
              <InputNumber className='number-input' min={0} max={targetMax} precision={0} addonAfter="#" />
            </Form.Item>
          </Col>
        </Row>
          
        <Row className='button-row'>
          <Col>
            <Button onClick={calculate}>
              Calculate
            </Button>
          </Col>
        </Row>
      </Form>

      {resultMode != -1 && (
        <div className='result-box'>
          <div className='result-header'>
            Result
          </div>
          
          <Form form={resultForm}>
            {resultMode != KakeraLootsCalculationMode.Estimation && (
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item name="klCount" label="Approximate kl needed">
                      <Input bordered={false} readOnly/>
                    </Form.Item>
                  </Col>
                </Row>

                <div className="result-sub-header">
                  Loots Breakdown
                </div>
              </>
            )}

            <ResultLoots />
          </Form>
        </div>
      )}
    </div>
  );
}

export default Loots;
