import { Button, Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import { copyToClipboard, IFormattedMessage } from "../shared/messageHelper";

interface IProps {
  index: number,
  message: IFormattedMessage
}

const ResultItem = ({ index, message } : IProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isForceCopied, setIsForcedCopied] = useState(false);
  const [isButtonHover, setIsButtonHover] = useState(false);

  useEffect(() => {
    setIsCopied(false);
  }, [message])

  const copy = useCallback(() => {
    copyToClipboard(message.text || '');

    setIsCopied(true);
    setIsForcedCopied(true);
  }, [message, setIsCopied, setIsForcedCopied]);
  
  return (
    <Col span={24} className='message-row'>
      <div>Message #{index + 1}: {message.itemCount} item{(message.itemCount || 0) > 1 ? 's' : ''}</div>
      <TextArea rows={4} value={message.text} readOnly/>
      <Button 
        className='copy-button' 
        onClick={() => copy()} 
        onMouseEnter={() => setIsButtonHover(true)}
        onMouseLeave={() => { setIsButtonHover(false); setIsForcedCopied(false) }}
      >
        { (isForceCopied || (isCopied && !isButtonHover)) ? 'Copied' : 'Copy' }
      </Button>
    </Col>
  )
};

export default ResultItem;
