import { message } from 'antd';

const defaultCommandLength = 50;
const defaultCharLimit = 2000;
const nitroCharlimit = 4000;

export const getLimit = function(isNitro: boolean, command: string) {
  return (isNitro ? nitroCharlimit : defaultCharLimit) - ((command?.length || 0) > 0 ? 0 : defaultCommandLength);
};

export interface IFormattedMessage {
  itemCount: number,
  text: string,
};

export const buildMessages = function(items: string[], charLimit: number, prefix: string, suffix: string, delimiter: string) {
  let lists = [];
  let currentCount = 0;
  let currentList = prefix;

  for (var i = 0; i < items.length; i++) {
    let next = items[i];
    let remaining = charLimit - currentList.length;

    if (remaining < (next.length + suffix.length)) {
      lists.push({ itemCount: currentCount, text: currentList.slice(0, -delimiter.length) + suffix });
      currentCount = 0
      currentList = prefix;
    }

    currentCount++;
    currentList += next + delimiter;
  }

  if (currentList.length > prefix.length) {
    lists.push({ itemCount: currentCount, text: currentList.slice(0, -delimiter.length) + suffix });
  }

  return lists;
};

export const copyToClipboard = function(text: string) {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
};