import { Col, Form, InputNumber, Row, Tooltip } from "antd";
import { Mode } from "./types";

interface IProps {
  mode: Mode
}

const InputRolls = ({ mode } : IProps) => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item 
          name='baseRolls'
          label={
            <Tooltip title='"Rolls Per Hour" from $settings'>
              <span>Base Rolls</span>
            </Tooltip>
          }
          rules={[{ required: true, message: 'Base Rolls is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='#'/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name='bonusRolls'
          label={
            <Tooltip title='"Rolls Per Hour" from $bonus (First bold number)'>
              <span>Bonus Rolls</span>
            </Tooltip>
          }
          rules={[{ required: true, message: 'Bonus Rolls is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='#'/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name='boostWishRolls'
          label={
            <Tooltip title='"Rolls invested" from $bwx'>
              <span>Boost Wish Rolls</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: 'Boost Wish Rolls is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='#'/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name='boostKakeraRolls'
          label={
            <Tooltip title='"Rolls invested" from $bkx'>
              <span>Boost Kakera Rolls</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: 'Boost Kakera Rolls is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='#'/>
        </Form.Item>
      </Col>

      {mode === Mode.Optimize && (
        <Col span={24}>
          <Form.Item 
            name='maxAllowedRolls'
            label={
              <Tooltip title='Maximum number of rolls you want to do per hour (leave empty for no limit)'>
                <span>Max Hourly Rolls</span>
              </Tooltip>
            }>
            <InputNumber className='number-input' min={0} precision={0} addonAfter='#'/>
          </Form.Item>
        </Col>
      )}
    </Row>
  )
};

export default InputRolls;
