import { Col, Form, FormInstance, InputNumber, Radio, Row, Tooltip } from "antd";
import { useCallback, useEffect } from "react";
import { BoostWishInput } from "../../api/client";

interface IProps {
  form: FormInstance<BoostWishInput>
}

const InputRoulette = ({ form } : IProps) => {
  useEffect(() => {
    updateTotalCount();
  }, [form]);

  const updateTotalCount = useCallback(() => {
    form.setFieldValue(
      'totalCount',
      (form.getFieldValue('ownedStarWishCount') || 0)
      + (form.getFieldValue('unclaimedStarWishCount') || 0)
      + (form.getFieldValue('claimedStarWishCount') || 0)
      + (form.getFieldValue('ownedWishCount') || 0)
      + (form.getFieldValue('unclaimedWishCount') || 0)
      + (form.getFieldValue('claimedWishCount') || 0)
      + (form.getFieldValue('ownedNormalCount') || 0)
      + (form.getFieldValue('unclaimedNormalCount') || 0)
      + (form.getFieldValue('claimedNormalCount') || 0))
  }, [form]);

  return (
    <Row>
      <Col span={24}>
        <Form.Item 
          name="ownedStarWishCount" 
          label={
            <Tooltip title="Number of owned starwishes that can be rolled">
              <span>Owned Starwishes</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Owned Starwishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="unclaimedStarWishCount" 
          label={
            <Tooltip title="Number of unclaimed starwishes that can be rolled">
              <span>Unclaimed Starwishes</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Unclaimed Starwishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="claimedStarWishCount" 
          label={
            <Tooltip title="Number of claimed (that you do not own) starwishes that can be rolled">
              <span>Claimed Starwishes</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Claimed Starwishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name="ownedWishCount" 
          label={
            <Tooltip title="Number of owned wishes that can be rolled (excluding $sw)">
              <span>Owned Wishes (Excluding $sw)</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Owned Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="unclaimedWishCount" 
          label={
            <Tooltip title="Number of unclaimed wishes that can be rolled (excluding $sw)">
              <span>Unclaimed Wishes (Excluding $sw)</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Unclaimed Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="claimedWishCount" 
          label={
            <Tooltip title="Number of claimed (that you do not own) wishes that can be rolled (excluding $sw)">
              <span>Claimed Wishes (Excluding $sw)</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Claimed Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name="ownedNormalCount" 
          label={
            <Tooltip title="Number of owned non-wishes that can be rolled">
              <span>Owned Non-Wishes</span>
            </Tooltip>
          }
          rules={[{ required: true, message: "Owned Non-Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="unclaimedNormalCount" 
          label={
            <Tooltip title="Number of unclaimed non-wishes that can be rolled">
              <span>Unclaimed Non-Wishes</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: "Unclaimed Non-Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item 
          name="claimedNormalCount" 
          label={
            <Tooltip title="Number of claimed (that you do not own) non-wishes that can be rolled">
              <span>Claimed Non-Wishes</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: "Claimed Non-Wishes is required" }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter="#" onChange={updateTotalCount}/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name="totalCount" 
          label={
            <Tooltip title="Number of characters that can be rolled">
              <span>Number of Rollable Characters</span>
            </Tooltip>
          }
          className="readonly-form-item">
          <InputNumber className='number-input' bordered={false} readOnly/>
        </Form.Item>
      </Col>
    </Row>
  )
};

export default InputRoulette;
