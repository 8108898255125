import { Col } from "antd";
import ResultMessage from "./resultMessage";
import { IMessageGroup } from './types';

interface IProps {
  index: number
  group: IMessageGroup
}

const ResultGroup = ({ index, group } : IProps) => {
  return (
    <Col span={24} className='message-row'>
      {group.isGroup && (
        <div>Group #{index + 1}: {group.key}</div>
      )}
      
      {group.messages.map((value, index) =>
        <ResultMessage key={index} index={index} message={value}></ResultMessage>
      )}
    </Col>
  )
};

export default ResultGroup;
