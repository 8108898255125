import './termsOfService.scss';
import '../shared.scss';

import { Typography } from 'antd';

function TermsOfService() {
  const { Title, Paragraph } = Typography;
  
  return (
    <div className='terms-of-service'>
      <Title>
        Terms Of Service
      </Title>
      <Paragraph>
        The Terms Of Service below are specific to the discord application Mudae Utils.
      </Paragraph>
      <Title level={3}>
        Agreement to Terms
      </Title>
      <Paragraph>
        These Terms of Service ("ToS") constitute an agreement made between you ("you", "your") and Mudae Utils ("our", "we", "us").
        You agree that by accessing the Bot, you have read, understood, and agree to be bound by both <a href='https://discord.com/terms'>Discord ToS</a> and all of the Terms of Service listed below.
        Additionaly you acknowledge that you have read and agree to our <a href='../privacy-policy'>Privacy Policy</a>.
        If you do not agree with any of these Terms, then you are prohibited from using the bot and must refrain from any further use.
      </Paragraph>
      <Title level={3}>
        Access to Our Service
      </Title>
      <Paragraph>
        You have the right to use and access our service so long as you do not break <a href='https://discord.com/terms'>Discord ToS</a> or the rules of the server that you are acessing our service from.
        We reserve the right to permanently block you from our service without warning.
      </Paragraph>
      <Title level={3}>
        Affiliation
      </Title>
      <Paragraph>
        We are in no way affiliated with or supported by Discord or Mudae.
        Any apparent connection to Discord or Mudae is purely coincidental.
      </Paragraph>
      <Title level={3}>
        Liability
      </Title>
      <Paragraph>
        We are not responsible and may not be held liable for any content generated via user run bot commands.
        <br/> <br/>
        We reserve the right to update these terms at our discretion.
      </Paragraph>
      <Title level={3}>
        Contact
      </Title>
      <Paragraph>
        You may get in direct contact with us through our <a href='https://discord.gg/WjJz97QKeQ '>Support Server</a>.
      </Paragraph>
    </div>
  );
}

export default TermsOfService;
