import './disableListGen.scss';
import '../shared.scss';

import { Button, Checkbox, Col, Form, InputNumber, Row, Select, Tabs, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DisableListGenerateInput, DisableListGenerateResult, DisableListRoulette, DisableListToggleDto } from '../../api/client';
import { DisableListApi } from '../../api/clientFactory';
import { IForm } from './types';
import Seo from '../shared/seo';
import TextArea from 'antd/es/input/TextArea';

function DisableListGen() {
  const { Title, Paragraph } = Typography;
  const { Option } = Select;
  
  const [form] = Form.useForm<IForm>();

  const [updateDateTime, setUpdateDateTime] = useState<string>('Checking...');
  const [waLimit, setWaLimit] = useState<number>(0)
  const [haLimit, setHaLimit] = useState<number>(0)
  const [wgLimit, setWgLimit] = useState<number>(0)
  const [hgLimit, setHgLimit] = useState<number>(0)
  const [toggles, setToggles] = useState<DisableListToggleDto[]>([]);
  
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const [result, setResult] = useState<DisableListGenerateResult | null>(null);
  const [listText, setListText] = useState<string>('');
  const [isForceCopied, setIsForcedCopied] = useState(false);

  useEffect(() => {
    DisableListApi
      .getInfo()
      .then((response) => {
        if (response.updateDateTime) {
          setUpdateDateTime(`${response.updateDateTime.toLocaleDateString()} at ${response.updateDateTime?.toLocaleTimeString(undefined, {timeZoneName: 'short'})}`);  
        } else {
          setUpdateDateTime('Sometime in the past 14 days');
        }

        setWaLimit(response?.waLimit || 0);
        setHaLimit(response?.haLimit || 0);
        setWgLimit(response?.wgLimit || 0);
        setHgLimit(response?.hgLimit || 0);
      });
      
    DisableListApi
      .getToggles()
      .then((response) => {
        setToggles(response || []);
      });
  }, [setUpdateDateTime, setWaLimit, setHaLimit, setWgLimit, setHgLimit, setToggles]);

  const handleGenerate = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setIsRunning(true);
        setResult(null);
        
        let input = new DisableListGenerateInput();
        input.slots = values.slots || 0
        input.overlap = values.overlap || 0
        input.roulette = values.roulette || 0
        input.toggleIds = values.toggleIds || []

        DisableListApi
          .generate(input)
          .then((response) => {
            setResult(response);
            setListText('$d ' + (response?.items || []).join('$'));
          })
          .finally(() => {
            setIsRunning(false);
          });
      });
  }, [form, setIsRunning, setResult, setListText]);

  const copyToClipboard = useCallback(() => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(listText);
    } else {
      document.execCommand('copy', true, listText);
    }

    setIsForcedCopied(true);
  }, [listText, setIsForcedCopied]);

  return (
    <div className='disable-list-gen'>
      <Seo title='Mudae Disablelist Generator' description='Instantly create a custom disablelist based on your settings'/>
      <Title>
        Disablelist Generator
      </Title>
      <Title level={3}>
        Notes
      </Title>
      <Paragraph>
        <ul>
          <li>Database Last updated: {updateDateTime}</li>
        </ul>
      </Paragraph>
      
      <Form layout="vertical" form={form}>
      <Row>
        <Col span={24}>
          <Title level={3}>
            Settings
          </Title>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item name="slots" label="Slots" rules={[{ required: true, message: 'Slots is required' }]}>
            <InputNumber className='number-input' min={10} max={100} precision={0}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="overlap" label="Overlap" rules={[{ required: true, message: 'Overlap is required' }]}>
            <InputNumber className='number-input' min={10000} max={150000} precision={0}/>
          </Form.Item>
        </Col>
        <Col span={8}>
            <Form.Item name="roulette" label="Roulette"  rules={[{ required: true, message: 'Roulette is required' }]}>
                <Select placeholder="Select Roulette">
                  <Option value={DisableListRoulette.Wa}>Wa</Option>
                  <Option value={DisableListRoulette.Ha}>Ha</Option>
                  <Option value={DisableListRoulette.Wg}>Wg</Option>
                  <Option value={DisableListRoulette.Hg}>Hg</Option>
                </Select>
            </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={24}>
          <Title level={5}>
            Toggles
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item name='toggleIds'>
            <Checkbox.Group className="toggle-checkbox-group" options={toggles.map(x => ({value: x.id || 0, label: x.name}))}/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Button type="primary" loading={isRunning} onClick={handleGenerate}>
            Generate
          </Button>
        </Col>
      </Row>

      {result && (
        <Row>
          <Col span={24}>
            <Title level={3}>
              Disablelist
            </Title>
          </Col>
          <Col span={24}>
            <Paragraph>
              Slots: {result?.slots || 0}{result?.maxSlots ? `/${result?.maxSlots}` : ''}
              , Overlap: {result?.overlap || 0}{result?.maxOverlap ? `/${result?.maxOverlap}` : ''}
            </Paragraph>
            <Paragraph>
              {result?.totalCharacters || 0} disabled, (
              <span>{result?.waDisabled || 0}</span>/{waLimit} $wa,&nbsp;
              <span>{result?.haDisabled || 0}</span>/{haLimit} $ha,&nbsp;
              <span>{result?.wgDisabled || 0}</span>/{wgLimit} $wg,&nbsp;
              <span>{result?.hgDisabled || 0}</span>/{hgLimit} $hg)
            </Paragraph>
          </Col>

          <Col span={24} className='message-row'>
            <TextArea rows={5} value={listText} readOnly/>
            <Button 
              className='copy-button' 
              onClick={() => copyToClipboard()} 
              onMouseLeave={() => { setIsForcedCopied(false) }}
            >
              {isForceCopied  ? 'Copied' : 'Copy'}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
    </div>
  );
}

export default DisableListGen;
