import './disableList.scss';
import '../shared.scss';

import { Form, Tabs, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DisableListCharacterDto, DisableListCheckInput, DisableListItemBasicDto, DisableListItemDto, DisableListItemType, DisableListRoulette, DisableListSearchInput, DisableListSortColumn, DisableListSortDirection, DisableListTextSearchInput, DisableListToggleDto, DisableListTotalsDto } from '../../api/client';
import { DisableListApi } from '../../api/clientFactory';
import { IConfigForm, IDisabledItem, ISearchForm } from './types';
import Config from './config';
import Search from './search';
import Details from './details';
import Seo from '../shared/seo';

function DisableList() {
  const { Title, Paragraph } = Typography;

  const [activeKey, setActiveKey] = useState<string>('2');

  const [configForm] = Form.useForm<IConfigForm>();
  const [searchForm] = Form.useForm<ISearchForm>();

  const [cacheKey, setCacheKey] = useState<number | undefined>(undefined)
  const [updateDateTime, setUpdateDateTime] = useState<string>('Checking...');
  const [waLimit, setWaLimit] = useState<number>(0)
  const [haLimit, setHaLimit] = useState<number>(0)
  const [wgLimit, setWgLimit] = useState<number>(0)
  const [hgLimit, setHgLimit] = useState<number>(0)
  const [toggles, setToggles] = useState<DisableListToggleDto[]>([]);
  
  const [maxSlots, setMaxSlots] = useState<number>(0);
  const [maxOverlap, setMaxOverlap] = useState<number>(0);

  const [roulette, setRoulette] = useState<DisableListRoulette | undefined>();
  const [minEfficiency, setMinEfficiency] = useState<number>(0);
  const [minDisabled, setMinDisabled] = useState<number>(0);
  const [searchName, setSearchName] = useState<string>('');

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);

  const [searchSortColumn, setSearchSortColumn] = useState<DisableListSortColumn>(DisableListSortColumn.Actual);
  const [searchSortDirection, setSearchSortDirection] = useState<DisableListSortDirection>(DisableListSortDirection.Descending);

  const [checkSortColumn, setCheckSortColumn] = useState<DisableListSortColumn>(DisableListSortColumn.Actual);
  const [checkSortDirection, setCheckSortDirection] = useState<DisableListSortDirection>(DisableListSortDirection.Descending);

  const [adl, setAdl] = useState<DisableListItemBasicDto[]>([]);
  const [sdl, setSdl] = useState<DisableListCharacterDto[]>([]);

  const [suggestedTags, setSuggestedTags] = useState<DisableListItemDto[]>([]);
  const [suggestedItems, setSuggestedItems] = useState<DisableListItemDto[]>([]);
  const [bundleSeriesNotFound, setBundleSeriesNotFound] = useState<string[]>([]);

  const [totalSlots, setTotalSlots] = useState<number>(0);
  const [totalOverlap, setTotalOverlap] = useState<number>(0);

  const [tags, setTags] = useState<IDisabledItem[]>([]);
  const [bundles, setBundles] = useState<IDisabledItem[]>([]);
  const [series, setSeries] = useState<IDisabledItem[]>([]);
  
  const [disabledTotals, setDisabledTotals] = useState<DisableListTotalsDto>(new DisableListTotalsDto());
  const [disabledTags, setDisabledTags] = useState<DisableListItemDto[]>([]);
  const [disabledItems, setDisabledItems] = useState<DisableListItemDto[]>([]);
  
  useEffect(() => {
    DisableListApi
      .getInfo()
      .then((response) => {
        setCacheKey(response?.cacheKey)

        if (response.updateDateTime) {
          setUpdateDateTime(`${response.updateDateTime.toLocaleDateString()} at ${response.updateDateTime?.toLocaleTimeString(undefined, {timeZoneName: 'short'})}`);  
        } else {
          setUpdateDateTime('Sometime in the past 14 days');
        }

        setWaLimit(response?.waLimit || 0);
        setHaLimit(response?.haLimit || 0);
        setWgLimit(response?.wgLimit || 0);
        setHgLimit(response?.hgLimit || 0);
        setToggles(response?.toggles || []);
      });
  }, [setCacheKey, setUpdateDateTime, setWaLimit, setHaLimit, setWgLimit, setHgLimit, setToggles]);
  
  useEffect(() => {
    //searchForm.setFieldValue('roulette', DisableListRoulette.Wa);
    searchForm.setFieldValue('minEfficiency', 0);
    searchForm.setFieldValue('minDisabled', 0);
  }, [searchForm]);

  const search = useCallback(() => {
    let input = new DisableListSearchInput();
    
    input.cacheKey = cacheKey

    input.serverDisableIds = sdl.map(x => x.id || 0);
    input.antiDisableIds = adl.map(x => x.id || 0);
    input.toggleIds = configForm.getFieldValue('toggleIds') || [];
    input.tagIds = tags.map(x => x.id || 0);
    input.bundleIds = bundles.map(x => x.id || 0);
    input.seriesIds = series.map(x => x.id || 0);

    input.roulette = roulette;
    input.minEfficiency = minEfficiency;
    input.minDisabled = minDisabled;
    input.name = searchName;
    input.sortColumn = searchSortColumn;
    input.sortDirection = searchSortDirection;
      
    DisableListApi
      .search(input)
      .then((response) => {
        setDisabledTotals(response.totals || new DisableListTotalsDto());
        setSuggestedTags(response?.items?.filter(x => x.type === DisableListItemType.Tag) || []);
        setSuggestedItems(response?.items?.filter(x => x.type !== DisableListItemType.Tag) || []);
      });
  }, [configForm, sdl, adl, tags, bundles, series, roulette, minEfficiency, minDisabled, searchName, searchSortColumn, searchSortDirection, setSuggestedTags, setSuggestedItems, setDisabledTotals]);

  const check = useCallback(() => {
    let input = new DisableListCheckInput();
    
    input.cacheKey = cacheKey

    input.serverDisableIds = sdl.map(x => x.id || 0);
    input.antiDisableIds = adl.map(x => x.id || 0);
    input.toggleIds = configForm.getFieldValue('toggleIds') || [];
    input.tagIds = tags.map(x => x.id || 0);
    input.bundleIds = bundles.map(x => x.id || 0);
    input.seriesIds = series.map(x => x.id || 0);
    input.sortColumn = checkSortColumn;
    input.sortDirection = checkSortDirection;
    
    input.roulette = roulette;

    DisableListApi
      .check(input)
      .then((response) => {
        setDisabledTotals(response.totals || new DisableListTotalsDto());
        setDisabledTags(response?.items?.filter(x => x.type === DisableListItemType.Tag) || []);
        setDisabledItems(response?.items?.filter(x => x.type !== DisableListItemType.Tag) || []);
      });
  }, [configForm, sdl, adl, tags, bundles, series, roulette, checkSortColumn, checkSortDirection, setDisabledTags, setDisabledItems, setDisabledTotals])

  const handleTabChange = useCallback((key: string) => {
    setActiveKey(key);
  }, [setActiveKey, search])
  
  useEffect(() => {
    if (roulette !== undefined) {
      
      if (activeKey === '2') {
        search();
      } else if (activeKey ==='3') {
        check()
      }
    }

    setTotalSlots(bundles.length + series.length);
    setTotalOverlap(tags.concat(bundles).concat(series).map(a => a.size || 0).reduce((a, b) => a + b, 0));
  }, [search, check, activeKey, tags, bundles, series, setTotalSlots, setTotalOverlap, roulette, minEfficiency, minDisabled, searchName, searchSortColumn, searchSortDirection, checkSortColumn, checkSortDirection]);

  const addItem = useCallback((item: DisableListItemDto) => {
    let ditem: IDisabledItem = { id: item.id, size: item.size } 
    switch (item.type) {
      case DisableListItemType.Tag:
        setTags(pre => [...pre, ditem].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i));
        break;
      case DisableListItemType.Bundle:
        setBundles(pre => [...pre, ditem].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i));
        break;
      case DisableListItemType.Series:
        setSeries(pre => [...pre, ditem].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i));
        break;
    }
  }, [setTags, setBundles, setSeries]);

  const removeItem = useCallback((item: DisableListItemDto) => {
    switch (item.type) {
      case DisableListItemType.Tag:
        setTags(pre => pre.filter(x => x.id !== item.id));
        break;
      case DisableListItemType.Bundle:
        setBundles(pre => pre.filter(x => x.id !== item.id));
        break;
      case DisableListItemType.Series:
        setSeries(pre => pre.filter(x => x.id !== item.id));
        break;
    }
  }, [setTags, setBundles, setSeries]);

  const handleBulkBundleSeriesSearch = useCallback((text: string) => {
    setBundleSeriesNotFound([]);
    
    let input = new DisableListTextSearchInput();
    input.cacheKey = cacheKey
    input.text = text

    DisableListApi
      .bulkSearchBundlesAndSeries(input)
      .then((response) => {
        let bundles = (response.bundles || []).map(x => { return { id: x.id, size: x.size } });
        let series = (response.series || []).map(x => { return { id: x.id, size: x.size } });

        setBundles(pre => [...pre, ...bundles].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i));
        setSeries(pre => [...pre, ...series].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i));

        setBundleSeriesNotFound(response?.notFound || []);
      });
  }, [setBundles, setSeries, setBundleSeriesNotFound])

  return (
    <div className='disable-list'>
      <Seo title='Mudae Disablelist Builder' description='Interactive UI for building efficient Mudae disablelists'/>
      <Title>
        Disablelist Builder
      </Title>
      <Title level={3}>
        Notes
      </Title>
      <Paragraph>
        <ul>
          <li>
            Database is updated periodically so some difference in disabled amounts is expected
          </li>
          <li>Last updated: {updateDateTime}</li>
        </ul>
      </Paragraph>

      <Tabs activeKey={activeKey} type="card" tabBarGutter={3} onChange={handleTabChange}>
        <Tabs.TabPane tab="Config" key="1">
          <Config cacheKey={cacheKey} toggles={toggles} form={configForm} setMaxSlots={setMaxSlots} setMaxOverlap={setMaxOverlap} adl={adl} setAdl={setAdl} sdl={sdl} setSdl={setSdl}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Search" key="2">
          <Search 
            form={searchForm}
            disabledTotals={disabledTotals}
            suggestedTags={suggestedTags}
            suggestedItems={suggestedItems}
            maxSlots={maxSlots}
            maxOverlap={maxOverlap}
            waLimit={waLimit}
            haLimit={haLimit}
            wgLimit={wgLimit}
            hgLimit={hgLimit}
            roulette={roulette}
            totalSlots={totalSlots}
            totalOverlap={totalOverlap}
            setRoulette={setRoulette}
            setMinEfficiency={setMinEfficiency}
            setMinDisabled={setMinDisabled}
            setSearchName={setSearchName}
            setSortColumn={setSearchSortColumn}
            setSortDirection={setSearchSortDirection}
            handleBulkBundleSeriesSearch={handleBulkBundleSeriesSearch}
            bundleSeriesNotFound={bundleSeriesNotFound}
            isSearching={isSearching}
            addItem={addItem}/>
        </Tabs.TabPane>
        {roulette !== undefined && (
          <Tabs.TabPane tab="Details" key="3">
            <Details 
              disabledTotals={disabledTotals}
              disabledTags={disabledTags}
              disabledItems={disabledItems}
              maxSlots={maxSlots}
              maxOverlap={maxOverlap}
              waLimit={waLimit}
              haLimit={haLimit}
              wgLimit={wgLimit}
              hgLimit={hgLimit}
              roulette={roulette}
              totalSlots={totalSlots}
              totalOverlap={totalOverlap}
              setSortColumn={setCheckSortColumn}
              setSortDirection={setCheckSortDirection}
              isChecking={isChecking}
              removeItem={removeItem}/>
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default DisableList;
