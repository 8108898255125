import './home.scss';
import './shared.scss'

import { Typography } from 'antd';
import Seo from './shared/seo';

function Home() {
  const { Title, Paragraph } = Typography;

  return (
    <div className="home">
      <Seo title='Mudae Utils' description='Collection of Mudae specific tools and utilities'/>
      <Title>
        Welcome
      </Title>
      <Paragraph>
        This site is still a work in progress, but I'll do my best to get updates out as soon as I can. In the meantime feel free to check out the currently implemented features.
      </Paragraph>

      <Title level={2}>
        Planned Updates
      </Title>
      <Paragraph>
        <ul>
          <li>Add Wish Protect logic to Roll Outcome Calculator</li>
          <li>Improve accuracy of Kakera Loots Calculator</li>
          <li>Improve overall UI of site, especially mobile view</li>
        </ul>
      </Paragraph>

      <Title level={2}>
        Contact
      </Title>
      <Paragraph>
        For any questions, suggestions or feedback you can contact me on discord, my username is heiea. 
      </Paragraph>
    </div>
  );
}

export default Home;
