import { Col, Form, InputNumber, Row, Tooltip } from "antd";
import { Mode } from "./types";

interface IProps {
  mode: Mode
}

const InputBonuses = ({ mode } : IProps) => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item 
          name='starWishBonus'
          label={
            <Tooltip title='"Additional % spawn bonus for $starwish" from $bonus (First bold Number)'>
              <span>Star Wish Bonus</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: 'Star Wish Bonus is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='%'/>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item 
          name='wishBonus'
          label={
            <Tooltip title='"Spawn bonus for wishes" from $bonus'>
              <span>Wish Bonus</span>
            </Tooltip>
          } 
          rules={[{ required: true, message: 'Wish Bonus is required' }]}>
          <InputNumber className='number-input' min={0} precision={0} addonAfter='%'/>
        </Form.Item>
      </Col>
      
      <Col span={24}>
        <Form.Item 
          name='persrare'
          label={
            <Tooltip title='Your current $persrare setting'>
              <span>Persrare</span>
            </Tooltip>
          }
          rules={[{ required: true, message: 'Persrare is required' }]}>
          <InputNumber className='number-input' min={1} precision={0} addonAfter='#'/>
        </Form.Item>
      </Col>
      
      {mode === Mode.Optimize && (
        <Col span={24}>
          <Form.Item 
            name='doubleKeyRate'
            label={
              <Tooltip title='Approximate chance of getting double keys on wished characters'>
                <span>Double Key Chance</span>
              </Tooltip>
            }
            rules={[{ required: true, message: 'Double Key Chance is required' }]}>
            <InputNumber className='number-input' min={0} max={100} precision={1} addonAfter='%'/>
          </Form.Item>
        </Col>
      )}
    </Row>
  )
};

export default InputBonuses;
