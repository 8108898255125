import { MinusOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Form, FormInstance, Input, Row, Table, Typography } from "antd";
import { ColumnsType } from 'antd/es/table';
import { useCallback, useState } from "react";
import { DisableListItemBasicDto, DisableListTextSearchInput } from '../../api/client';
import { DisableListApi } from "../../api/clientFactory";
import { IConfigForm } from "./types";

interface IProps {
  cacheKey: number | undefined

  form: FormInstance<IConfigForm>

  adl: DisableListItemBasicDto[]
  setAdl: React.Dispatch<React.SetStateAction<DisableListItemBasicDto[]>>
}

const AdlConfig = ({ cacheKey, form, adl, setAdl } : IProps) => {
  const { Title, Paragraph } = Typography;
  const { TextArea } = Input;
  
  const [adlOptions, setAdlOptions] = useState<{ value: number; label: string; data: DisableListItemBasicDto }[]>([]);
  const [adlNotFoundList, setAdlNotFoundList] = useState<String[]>([]);

  const adlColumns: ColumnsType<DisableListItemBasicDto> = [
    {
      title: '',
      key: 'action',
      width: 50,
      render: (_, record) => <Button shape="circle" size="small" onClick={() => { adlRemove(record) }} icon={<MinusOutlined />} />
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Size',
      dataIndex: 'size',
      width: 40,
    },
    {
      title: '$wa',
      dataIndex: 'numWa',
      width: 40,
    },
    {
      title: '$ha',
      dataIndex: 'numHa',
      width: 40,
    },
    {
      title: '$wg',
      dataIndex: 'numWg',
      width: 40,
    },
    {
      title: '$hg',
      dataIndex: 'numHg',
      width: 40,
    },
  ];

  const adlAdd = useCallback((item: DisableListItemBasicDto) => {
    setAdl(pre => [...pre, item].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i).sort((a, b) => (a.name || '').localeCompare((b.name || ''))));
  }, [setAdl]);
  
  const adlRemove = useCallback((item: DisableListItemBasicDto) => {
    setAdl(pre => pre.filter(x => x.id !== item.id));
  }, [setAdl]);

  const handleAdlSearch = useCallback((value: string) => {
    if (value) {
      DisableListApi
        .searchSeries(value, cacheKey)
        .then((response) => {
          setAdlOptions(response.map(x => { return { value: x.id || 0, label: x.name || '', data: x }}))
        });
    } else {
      setAdlOptions([]);
    }
  }, [setAdlOptions]);

  const handleAdlSelect = useCallback((value: string, option: { value: number; label: string; data: DisableListItemBasicDto }) => {
    adlAdd(option.data)
    form.setFieldValue('adlName', '')
    setAdlOptions([])
  }, [form, adlAdd, setAdlOptions]);

  const handleAdlBulkSearch = useCallback(() => {
    let input = new DisableListTextSearchInput();
    input.cacheKey = cacheKey
    input.text = form.getFieldValue('adlNames');

    setAdlNotFoundList([]);

    DisableListApi
      .bulkSearchSeries(input)
      .then((response) => {
        let series = response.series || []
        setAdl(pre => [...pre, ...series].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i).sort((a, b) => (a.name || '').localeCompare((b.name || ''))));
        form.setFieldValue('adlNames', '')
        setAdlNotFoundList(response?.notFound || []);
      });
  }, [form, setAdl])

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>
          Anti-Disablelist
        </Title>
      </Col>
      <Col span={24}>
        <Paragraph>
          *Only supports Series Main names
        </Paragraph>
      </Col>
      <Col span={24}>
        <Form.Item name='adlName' label="Search">
          <AutoComplete
            onSearch={handleAdlSearch}
            onSelect={handleAdlSelect}
            options={adlOptions}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name='adlNames' label="Bulk Add">
          <TextArea rows={4} placeholder="DARLING in the FRANXX $ Re:Zero kara Hajimeru Isekai Seikatsu $ Kono Subarashii Sekai ni Shukufuku wo! ..."/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Button type="primary" icon={<SearchOutlined />} onClick={handleAdlBulkSearch}>
          Search
        </Button>
      </Col>
      {adlNotFoundList.length > 0 && (
        <Col span={24}>
          {adlNotFoundList.map((value, index) => 
            <Paragraph key={index} style={{color: 'red'}}>
              Series '{value}' not found!
            </Paragraph>
        )}
        </Col>
      )}
      <Col span={24} className="table-col">
        <Table columns={adlColumns} className='compressed-table' dataSource={adl} pagination={{ defaultPageSize: 10, showSizeChanger: false }} />
      </Col>
    </Row>
  )
};

export default AdlConfig;
