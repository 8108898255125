import { Col, Row, Typography } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/es/table/interface";
import { useCallback } from "react";
import { DisableListItemDto, DisableListRoulette, DisableListSortColumn, DisableListSortDirection, DisableListTotalsDto } from '../../api/client';
import ExportList from "./exportList";
import Summary from "./summary";
import TableItems from "./tableItems";

interface IProps {
  disabledTotals: DisableListTotalsDto
  disabledTags: DisableListItemDto[]
  disabledItems: DisableListItemDto[]

  maxSlots: number
  maxOverlap: number
  waLimit: number
  haLimit: number
  wgLimit: number
  hgLimit: number
  roulette: DisableListRoulette | undefined

  totalSlots: number
  totalOverlap: number
  
  setSortColumn: React.Dispatch<React.SetStateAction<DisableListSortColumn>>
  setSortDirection: React.Dispatch<React.SetStateAction<DisableListSortDirection>>
  
  isChecking: boolean
  removeItem: (item: DisableListItemDto) => void
}

const Details = ({ disabledTotals, disabledTags, disabledItems, maxSlots, maxOverlap, waLimit, haLimit, wgLimit, hgLimit, roulette, totalSlots, totalOverlap, isChecking, setSortColumn, setSortDirection, removeItem } : IProps) => {
  const { Title, Paragraph } = Typography;

  const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<DisableListItemDto> | SorterResult<DisableListItemDto>[], extra: TableCurrentDataSource<DisableListItemDto>) => {
    let castedSorter = sorter as SorterResult<DisableListItemDto>
    if (castedSorter.columnKey && castedSorter.order) {
      switch (castedSorter.columnKey) {
        case 'size':
          setSortColumn(DisableListSortColumn.Size);
          break;
        case 'actual':
          setSortColumn(DisableListSortColumn.Actual);
          break;
        case 'percent':
          setSortColumn(DisableListSortColumn.Percent);
          break;
        default:
          setSortColumn(DisableListSortColumn.Actual);
          break;
      }

      switch (castedSorter.order) {
        case 'ascend':
          setSortDirection(DisableListSortDirection.Ascending);
          break;
        case 'descend':
          setSortDirection(DisableListSortDirection.Descending);
          break;
        default:
          setSortDirection(DisableListSortDirection.Descending);
          break;
      }
    } else {
      setSortColumn(DisableListSortColumn.Actual);
      setSortDirection(DisableListSortDirection.Descending);
    }
  }, [setSortColumn, setSortDirection]);

  return (
    <div>
      <Summary disabledTotals={disabledTotals} maxSlots={maxSlots} maxOverlap={maxOverlap} waLimit={waLimit} haLimit={haLimit} wgLimit={wgLimit} hgLimit={hgLimit} totalSlots={totalSlots} totalOverlap={totalOverlap}/>

      {disabledTags.length > 0 && (
        <TableItems title="Tags" height={200} items={disabledTags} roulette={roulette} isLoading={isChecking} removeItem={removeItem}/>
      )}
      <TableItems title="Bundles & Series" height={300} items={disabledItems} roulette={roulette} isLoading={isChecking} onChange={handleTableChange} removeItem={removeItem}/>
      
      {(disabledTags.length > 0 || disabledItems.length > 0) && (
        <Row>
          <Col span={24}>
            <Title level={3}>
              Export
            </Title>
          </Col>
          <Col span={24}>
            {disabledTags.length > 0 && (
              <ExportList rows={1} text={'$dt ' + disabledTags.map(x => x.name).join('$')}/>
            )}
            {disabledItems.length > 0 && (
              <ExportList rows={3} text={'$d ' + disabledItems.map(x => x.name).join('$')}/>
            )}
          </Col>
        </Row>
      )}
    </div>
  )
};

export default Details;
