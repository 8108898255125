import './privacyPolicy.scss';
import '../shared.scss';

import { Typography } from 'antd';

function PrivacyPolicy() {
  const { Title, Paragraph } = Typography;
  
  return (
    <div className='privacy-policy'>
      <Title>
        Privacy Policy
      </Title>
      <Paragraph>
        The Privacy Policy below is specific to the discord application Mudae Utils.
      </Paragraph>
      <Title level={3}>
        Stored Data
      </Title>
      <Paragraph>
        The following data associated with a Server may be stored for an indefninite amount of time:
        <ul>
          <li>
            Server Id
          </li>
          <li>
            Language Setting
          </li>
        </ul>
        The following data associated with a User may be stored for an indefninite amount of time:
        <ul>
          <li>
            User Id
          </li>
          <li>
            Language Setting
          </li>
        </ul>
        None of the data collected by this service will be distributed to any other entity.
      </Paragraph>
      <Title level={3}>
        Removal of Data
      </Title>
      <Paragraph>
        All user or server related data will be deleted upon request.
        To submit a request please join the <a href='https://discord.gg/WjJz97QKeQ '>Support Server</a> and contact an admin there.
        Proof of ownership may be required.
      </Paragraph>
    </div>
  );
}

export default PrivacyPolicy;
