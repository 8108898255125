import { Col, Form, Input, Row } from "antd";

const ResultLoots = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="rolls" label="Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="wishes" label="Wishlist Slots">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="wishProtect" label="Wish Protect Level">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      
      <Col span={24}>
        <Form.Item name="overlap" label="Disablelist Overlap">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="limroulA" label="Animanga Limroul">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="limroulG" label="Game Limroul">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
    </Row>
  )
};

export default ResultLoots;