import { Col, Form, FormInstance, InputNumber, Row, Select, Tooltip, Typography } from "antd";
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/es/table/interface";
import { useCallback } from "react";
import { DisableListItemDto, DisableListRoulette, DisableListSortColumn, DisableListSortDirection, DisableListTotalsDto } from '../../api/client';
import Summary from "./summary";
import TableItems from "./tableItems";
import { ISearchForm } from "./types";

interface IProps {
  form: FormInstance<ISearchForm>

  disabledTotals: DisableListTotalsDto
  suggestedTags: DisableListItemDto[]
  suggestedItems: DisableListItemDto[]

  maxSlots: number
  maxOverlap: number
  waLimit: number
  haLimit: number
  wgLimit: number
  hgLimit: number
  roulette: DisableListRoulette | undefined

  totalSlots: number
  totalOverlap: number

  setRoulette: React.Dispatch<React.SetStateAction<DisableListRoulette | undefined>>
  setMinEfficiency: React.Dispatch<React.SetStateAction<number>>
  setMinDisabled: React.Dispatch<React.SetStateAction<number>>
  setSearchName: React.Dispatch<React.SetStateAction<string>>
  setSortColumn: React.Dispatch<React.SetStateAction<DisableListSortColumn>>
  setSortDirection: React.Dispatch<React.SetStateAction<DisableListSortDirection>>

  handleBulkBundleSeriesSearch: (text: string) => void
  bundleSeriesNotFound: string[]
  
  isSearching: boolean
  addItem: (item: DisableListItemDto) => void
}

const Search = ({ form, disabledTotals, suggestedTags, suggestedItems, maxSlots, maxOverlap, waLimit, haLimit, wgLimit, hgLimit, roulette, totalSlots, totalOverlap, setRoulette, setMinEfficiency, setMinDisabled, setSearchName, setSortColumn, setSortDirection, handleBulkBundleSeriesSearch, bundleSeriesNotFound, isSearching, addItem } : IProps) => {
  const { Title, Paragraph } = Typography;
  const { Option } = Select;

  const handleTableChange = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<DisableListItemDto> | SorterResult<DisableListItemDto>[], extra: TableCurrentDataSource<DisableListItemDto>) => {
    let castedSorter = sorter as SorterResult<DisableListItemDto>
    if (castedSorter.columnKey && castedSorter.order) {
      switch (castedSorter.columnKey) {
        case 'size':
          setSortColumn(DisableListSortColumn.Size);
          break;
        case 'actual':
          setSortColumn(DisableListSortColumn.Actual);
          break;
        case 'percent':
          setSortColumn(DisableListSortColumn.Percent);
          break;
        default:
          setSortColumn(DisableListSortColumn.Actual);
          break;
      }

      switch (castedSorter.order) {
        case 'ascend':
          setSortDirection(DisableListSortDirection.Ascending);
          break;
        case 'descend':
          setSortDirection(DisableListSortDirection.Descending);
          break;
        default:
          setSortDirection(DisableListSortDirection.Descending);
          break;
      }
    } else {
      setSortColumn(DisableListSortColumn.Actual);
      setSortDirection(DisableListSortDirection.Descending);
    }
  }, [setSortColumn, setSortDirection]);

  return (
    <div>
      <Form layout="vertical" form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Title level={3}>
              Filter
            </Title>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="roulette" 
              label={
                <Tooltip title="Roulette you will be rolling in">
                  <span>Roulette</span>
                </Tooltip>
              }>
                <Select onChange={value => setRoulette(value)} placeholder="Select Roulette">
                  <Option value={DisableListRoulette.Wa}>Wa</Option>
                  <Option value={DisableListRoulette.Ha}>Ha</Option>
                  <Option value={DisableListRoulette.Wg}>Wg</Option>
                  <Option value={DisableListRoulette.Hg}>Hg</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="minEfficiency" 
              label={
                <Tooltip title="Minimum percentage of new disabled characters out of entire bundle/series">
                  <span>Min %{(roulette == DisableListRoulette.Wa ? 'wa' : roulette == DisableListRoulette.Ha ? 'ha' : roulette == DisableListRoulette.Wg ? 'wg' : roulette == DisableListRoulette.Hg ? 'hg' : '')}</span>
                </Tooltip>
              }>
              <InputNumber className='number-input' min={0} precision={0} onBlur={e => { if (e.target.value) {setMinEfficiency(parseInt(e.target.value))} else {setMinEfficiency(0)}}}/>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              name="minDisabled" 
              label={
                <Tooltip title="Minimum number of new disabled characters">
                  <span>Min ${(roulette == DisableListRoulette.Wa ? 'wa' : roulette == DisableListRoulette.Ha ? 'ha' : roulette == DisableListRoulette.Wg ? 'wg' : roulette == DisableListRoulette.Hg ? 'hg' : '')}</span>
                </Tooltip>
              }>
              <InputNumber className='number-input' min={0} precision={0} onBlur={e => {if (e.target.value) {setMinDisabled(parseInt(e.target.value))} else {setMinDisabled(0)}}}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
      {roulette === undefined && (
        <Title level={4}>
          Please select a Roulette to begin
        </Title>
      )}

      {roulette !== undefined && (
        <div>
          <Summary disabledTotals={disabledTotals} maxSlots={maxSlots} maxOverlap={maxOverlap} waLimit={waLimit} haLimit={haLimit} wgLimit={wgLimit} hgLimit={hgLimit} totalSlots={totalSlots} totalOverlap={totalOverlap}/>

          {suggestedTags.length > 0 && (
            <TableItems 
              title="Tags"
              height={200}
              items={suggestedTags}
              roulette={roulette}
              isLoading={isSearching}
              overlapLimit={maxOverlap > 0 ? maxOverlap - totalOverlap : undefined}
              waLimit={waLimit - (disabledTotals.totalWa || 0)}
              haLimit={haLimit - (disabledTotals.totalHa || 0)}
              wgLimit={wgLimit - (disabledTotals.totalWg || 0)}
              hgLimit={hgLimit - (disabledTotals.totalHg || 0)}
              addItem={addItem}/>
          )}

          <TableItems 
            title="Bundles & Series"
            description="*Only supports Bundle and Series Main names"
            height={300}
            items={suggestedItems}
            roulette={roulette}
            isLoading={isSearching}
            onChange={handleTableChange}
            slotLimit={maxSlots > 0 ? maxSlots - totalSlots : undefined}
            overlapLimit={maxOverlap > 0 ? maxOverlap - totalOverlap : undefined}
            waLimit={waLimit - (disabledTotals.totalWa || 0)}
            haLimit={haLimit - (disabledTotals.totalHa || 0)}
            wgLimit={wgLimit - (disabledTotals.totalWg || 0)}
            hgLimit={hgLimit - (disabledTotals.totalHg || 0)}
            onSearch={(text) => setSearchName(text)}
            onBulkSearch={handleBulkBundleSeriesSearch}
            bulkSearchPlaceholder="Kadokawa Corporation $ Western $ Shueisha ..."
            bulkSearchNotFound={bundleSeriesNotFound}
            addItem={addItem}/>
        </div>
      )}
    </div>
  )
};

export default Search;
