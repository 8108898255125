import './kakeraValue.scss';
import '../shared.scss';

import { Button, Col, Form, Input, InputNumber, Row, Typography } from 'antd';
import { useCallback, useEffect } from 'react';
import { IKakeraValueInputForm, IKakeraValueResultForm } from './types';
import Seo from '../shared/seo';

const breakpoints = [
  { 'keys': 10, 'value': 15 },
  { 'keys': 15, 'value': 15 },
  { 'keys': 20, 'value': 10 },
  { 'keys': 25, 'value': 10 },
  { 'keys': 30, 'value': 10 },
  { 'keys': 35, 'value': 5 },
  { 'keys': 40, 'value': 5 },
  { 'keys': 45, 'value': 5 },
  { 'keys': 50, 'value': 5 },
  { 'keys': 55, 'value': 5 },
  { 'keys': 60, 'value': 5 },
  { 'keys': 70, 'value': 5 },
  { 'keys': 80, 'value': 5 },
  { 'keys': 90, 'value': 5 },
  { 'keys': 100, 'value': 5 },
  { 'keys': 110, 'value': 5 },
  { 'keys': 120, 'value': 5 },
  { 'keys': 130, 'value': 5 },
  { 'keys': 140, 'value': 5 },
  { 'keys': 150, 'value': 5 },
  { 'keys': 160, 'value': 5 },
  { 'keys': 170, 'value': 5 },
  { 'keys': 180, 'value': 5 },
  { 'keys': 190, 'value': 5 },
  { 'keys': 200, 'value': 5 },
  { 'keys': 210, 'value': 5 },
  { 'keys': 220, 'value': 5 },
  { 'keys': 230, 'value': 5 },
  { 'keys': 240, 'value': 5 },
  { 'keys': 250, 'value': 5 },
  { 'keys': 260, 'value': 5 },
  { 'keys': 270, 'value': 5 },
  { 'keys': 280, 'value': 5 },
  { 'keys': 290, 'value': 5 },
  { 'keys': 300, 'value': 5 }
]

function KakeraValue() {
  const { Title, Paragraph } = Typography;

  const [inputForm] = Form.useForm<IKakeraValueInputForm>();
  const [resultForm] = Form.useForm<IKakeraValueResultForm>();
  
  useEffect(() => {
    inputForm.setFieldValue('claimRank', 1);
    inputForm.setFieldValue('likeRank', 1);
    inputForm.setFieldValue('numClaimedCharacters', 0);
    inputForm.setFieldValue('numKeys', 0);

    calculate();
  }, [inputForm]);

  const calculate = useCallback(() => {
    inputForm.validateFields();

    let claimRank = inputForm.getFieldValue('claimRank');
    let likeRank = inputForm.getFieldValue('likeRank');
    let numClaimedCharacters = inputForm.getFieldValue('numClaimedCharacters');
    let numKeys = inputForm.getFieldValue('numKeys');

    let rankValue = getRankValue(claimRank, likeRank);
    let keyBonus = getKeyBonus(numKeys);
    let claimMultiplier = getClaimMultiplier(numClaimedCharacters);
    let keyMultiplier = getKeyMultiplier(numKeys);

    let baseValue = Math.floor((rankValue + keyBonus) * claimMultiplier + 0.5);
    let totalValue = Math.floor(baseValue * keyMultiplier + 0.5)

    resultForm.setFieldValue('rankValue', Math.floor(rankValue + 0.5));
    resultForm.setFieldValue('keyBonus', keyBonus);
    resultForm.setFieldValue('claimMultiplier', `${claimMultiplier.toFixed(5)}x`);
    resultForm.setFieldValue('baseValue', baseValue);
    resultForm.setFieldValue('keyMultiplier', `${keyMultiplier.toFixed(2)}x`);
    resultForm.setFieldValue('totalValue', totalValue);

  }, [inputForm, resultForm]);

  const getRankValue = useCallback((claimRank: number, likeRank: number) => {
    let avgRank = (claimRank + likeRank) / 2
    return (25000 * (avgRank + 70) ** -0.75 + 20);
  }, []);

  const getKeyBonus = useCallback((numKeys: number) => {
    return breakpoints.filter(x => x.keys <= numKeys).reduce((sum, current) => sum + current.value, 0)
  }, []);

  const getClaimMultiplier = useCallback((numClaimedCharacters: number) => {
    return 1 + numClaimedCharacters / 5500
  }, []);

  const getKeyMultiplier = useCallback((numKeys: number) => {
    if (numKeys < 2) {
      return 1
    } else if (numKeys < 4) {
      return 1.1
    } else if (numKeys < 6) {
      return 1.1 + (numKeys - 3) * 0.1
    } else if (numKeys < 10) {
      return 1.3 + (numKeys - 6) * 0.1
    } else {
      return 1.6 + (numKeys - 10) * 0.05
    }
  }, []);
  
  return (
    <div className='kakera-value'>
      <Seo title='Mudae Kakera Value Calculator' description="Calculate a Mudae character's kakare value based on ranks, claims, and keys"/>
      <Title>
        Kakera Value Calculator
      </Title>

      <Form form={inputForm} layout="vertical">
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item 
              name="claimRank" 
              label="Claim Rank"
              rules={[{ required: true, message: "Claim Rank is required" }]}>
              <InputNumber className='number-input' min={1} precision={0} onChange={calculate}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="likeRank" 
              label="Like Rank"
              rules={[{ required: true, message: "Like Rank is required" }]}>
              <InputNumber className='number-input' min={1} precision={0} onChange={calculate}/>
            </Form.Item>
          </Col>
          
          <Col span={12}>
            <Form.Item 
              name="numClaimedCharacters" 
              label="Number of Claimed Characters"
              rules={[{ required: true, message: "Number of Claimed Characters is required" }]}>
              <InputNumber className='number-input' min={0} precision={0} onChange={calculate}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="numKeys" 
              label="Number of Keys"
              rules={[{ required: true, message: "Number of Keys is required" }]}>
              <InputNumber className='number-input' min={0} precision={0} onChange={calculate}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Form form={resultForm} layout="vertical">
        <Title level={2}>Result</Title>
        <Row>
          <Col span={24}>
            <Form.Item name="totalValue" label="Kakera Value">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
        </Row>
        
        <Title level={3}>Breakdown</Title>
        <Row>
          <Col span={8}>
            <Form.Item name="rankValue" label="Rank Value">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="keyBonus" label="Key Bonus">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="claimMultiplier" label="Claim Multiplier">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="baseValue" label="Base Value">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="keyMultiplier" label="Key Multiplier">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="totalValue" label="Total">
              <Input bordered={false} readOnly/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default KakeraValue;
