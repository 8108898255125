import './pageList.scss';
import '../shared.scss';

import { Button, Checkbox, Col, Form, Input, Row, Tooltip, message, InputNumber, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { IPageListForm } from './types';
import { buildMessages, copyToClipboard, getLimit, IFormattedMessage } from '../shared/messageHelper';
import TextArea from 'antd/es/input/TextArea';
import ResultItem from './resultItem';
import Seo from '../shared/seo';

function PageList() {
  const { Title } = Typography;
  
  const [form] = Form.useForm<IPageListForm>();

  const [itemCount, setItemCount] = useState<number>(0);
  const [formattedMessages, setFormattedMessages] = useState<IFormattedMessage[]>([]);

  useEffect(() => {
    form.setFieldValue('isNitroLimit', JSON.parse(localStorage.getItem('isNitro') || 'false'));
  }, [form]);

  const handleNitroToggle = useCallback((isNitro: boolean) => {
    localStorage.setItem('isNitro', JSON.stringify(isNitro));
  }, [])

  const generate = useCallback(() => {
    form.validateFields();
    
    let prefix = form.getFieldValue('prefix');
    if (prefix) {
      prefix = prefix.trim() + ' ';
    } else {
      prefix = 'page ';
    }

    let suffix = form.getFieldValue('suffix');
    if (!suffix) {
      suffix = ''
    }

    let separator = form.getFieldValue('separator');
    if (!separator) {
      separator = ' ';
    }

    let items = getPages(form.getFieldValue('pageRanges'));
    let limit = getLimit(form.getFieldValue('isNitroLimit'), prefix);

    let sortedItems = items
      .sort((n1,n2) => n1 - n2)
      .filter((elem, index, self) => index === self.indexOf(elem))
      .map(x => x.toString());

    setItemCount(sortedItems.length);
    setFormattedMessages(buildMessages(sortedItems, limit, prefix, suffix, separator));
  }, [form, setItemCount, setFormattedMessages]);

  const getPages = useCallback((pageRanges: string) => {
    var ranges = (pageRanges || '').split(',').filter(element => element);
    var pages: number[] = [];

    for (var i = 0; i < ranges.length; i++) {
      var range = ranges[i];
      var numbers = range.split('-').filter(element => element);

      if (numbers.length == 1) {
        var num = parseInt(numbers[0]);

        if (isNaN(num)) {
          message.error(`Invalid page range: ${range}`);
        }
        else {
          pages.push(num);
        }
      }
      else if (numbers.length == 2) {
        var num1 = parseInt(numbers[0]);
        var num2 = parseInt(numbers[1]);

        if (isNaN(num1) || isNaN(num2)) {
          message.error(`Invalid page range: ${range}`);
        } else {
          var lower = Math.min(num1, num2);
          var higher = Math.max(num1, num2);

          pages = pages.concat(Array.from(Array(higher - lower + 1).keys()).map(x => x + lower));
        }
      }
      else {
        message.error(`Invalid page range: ${range}`);
      }
    }

    return pages;
  }, []);

  return (
    <div className='page-list'>
      <Seo title='Mudae Page List Generator' description='Generate a lsit of pages for Mudae commands from specified page ranges'/>
      <Title>
        Page List Generator
      </Title>

      <Form form={form} layout="vertical">
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item 
              name="pageRanges" 
              label={
                <Tooltip title="List of page ranges separated by commas">
                  <span>Page Ranges</span>
                </Tooltip>
              }
              rules={[{ required: true, message: "Page Ranges is required" }]}>
              <Input placeholder='1, 3, 5-7, 9, 12-15'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item 
              name="prefix" 
              label={
                <Tooltip title="Alternative text you want at the start of each message">
                  <span>Prefix/Command</span>
                </Tooltip>
              }>
              <Input placeholder='$notepage'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="suffix" 
              label={
                <Tooltip title="Additional text you want at the end of each message">
                  <span>Suffix</span>
                </Tooltip>
              }>
              <Input placeholder='$Some note'/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="separator" 
              label={
                <Tooltip title="Alternative separator to use between page numbers">
                  <span>Separator</span>
                </Tooltip>
              }>
              <Input placeholder='$'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item name="isNitroLimit" valuePropName="checked">
              <Checkbox onChange={(e) => handleNitroToggle(e.target.checked)}>Nitro Character Limit</Checkbox>
            </Form.Item>
          </Col>
        </Row>
          
        <Row className='button-row'>
          <Col>
            <Button onClick={generate}>
              Generate
            </Button>
          </Col>
        </Row>

        {itemCount > 0 && (
          <Row className='message-list'>
            <Col span={24}>{itemCount} page{itemCount > 1 ? 's' : ''} split into {formattedMessages.length} message{formattedMessages.length > 1 ? 's' : ''}</Col>
            
            {formattedMessages.map((value, index) => 
              <ResultItem index={index} message={value}></ResultItem>
            )}
          </Row>
        )}
      </Form>
    </div>
  );
}

export default PageList;
