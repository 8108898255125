import { Checkbox, Col, Form, FormInstance, InputNumber, Row, Typography } from "antd";
import { DisableListCharacterDto, DisableListItemBasicDto, DisableListToggleDto } from '../../api/client';
import AdlConfig from "./adlConfig";
import SdlConfig from "./sdlConfig";
import { IConfigForm } from "./types";

interface IProps {
  cacheKey: number | undefined
  toggles: DisableListToggleDto[]

  form: FormInstance<IConfigForm>
  setMaxSlots: React.Dispatch<React.SetStateAction<number>>
  setMaxOverlap: React.Dispatch<React.SetStateAction<number>>
  
  adl: DisableListItemBasicDto[]
  setAdl: React.Dispatch<React.SetStateAction<DisableListItemBasicDto[]>>
  
  sdl: DisableListCharacterDto[]
  setSdl: React.Dispatch<React.SetStateAction<DisableListCharacterDto[]>>
}

const Config = ({ cacheKey, toggles, form, setMaxSlots, setMaxOverlap, adl, setAdl, sdl, setSdl } : IProps) => {
  const { Title } = Typography;
  
  return (
    <Form layout="vertical" form={form}>
      <Row>
        <Col span={24}>
          <Title level={3}>
            Settings
          </Title>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item name="slots" label="Slots">
            <InputNumber className='number-input' min={0} precision={0} onBlur={e => {if (e.target.value) {setMaxSlots(parseInt(e.target.value))} else {setMaxSlots(0)}}}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="overlap" label="Overlap">
            <InputNumber className='number-input' min={0} precision={0} onBlur={e => {if (e.target.value) {setMaxOverlap(parseInt(e.target.value))} else {setMaxOverlap(0)}}}/>
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={24}>
          <Title level={5}>
            Toggles
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item name='toggleIds'>
            <Checkbox.Group className="toggle-checkbox-group" options={toggles.map(x => ({value: x.id || 0, label: x.name}))}/>
          </Form.Item>
        </Col>
      </Row>
      
      <AdlConfig cacheKey={cacheKey} form={form} adl={adl} setAdl={setAdl}/>
      <SdlConfig cacheKey={cacheKey} form={form} sdl={sdl} setSdl={setSdl}/>
    </Form>
  )
};

export default Config;
