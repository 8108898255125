import './page.scss';

import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Layout, Menu, Grid } from 'antd';
import { useCallback, useState } from 'react';

const useCurrentPath = () => {
  var location = useLocation();
  return location.pathname
}

const { useBreakpoint } = Grid;

const Page = () => {
  const { Sider, Content } = Layout;

  const initialRoute = useCurrentPath();
  const breakpoints = useBreakpoint();

  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);

  const onSiderCollapse = useCallback((val: boolean) => {
    setCollapsed(val);
  }, []);

  const onMenuItemSelected = useCallback((breakpoints: any) => {
    if (!breakpoints.lg) {
      setCollapsed(true);
    }
  }, []);


  return (
    <Layout className='main-layout'>
      <Sider className='sider' breakpoint='lg' collapsedWidth='0' theme='light' collapsed={collapsed} onCollapse={onSiderCollapse}>
        <Menu mode='inline' className='side-menu' defaultSelectedKeys={[ initialRoute ]} onSelect={() => { onMenuItemSelected(breakpoints) }}>
          <Menu.Item key='/'>
            <NavLink to='/'>
              Home
            </NavLink>
          </Menu.Item>
          <Menu.ItemGroup title='Tools'>
            <Menu.Item key='/rolls'>
              <NavLink to='/rolls'>
                Rolls
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/loots'>
              <NavLink to='/loots'>
                Kakera Loots
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/disable-list'>
              <NavLink to='/disable-list'>
                DL Builder
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/disable-list-gen'>
              <NavLink to='/disable-list-gen'>
                DL Generator
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/kakera-value'>
              <NavLink to='/kakera-value'>
                Kakera Value
              </NavLink>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title='Utilities'>
            <Menu.Item key='/format-list'>
              <NavLink to='/format-list'>
                List Formatter
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/page-list'>
              <NavLink to='/page-list'>
                Page List Generator
              </NavLink>
            </Menu.Item>
            {/* <Menu.Item key='/privacy-policy'>
              <NavLink to='/privacy-policy'>
                Page List Generator
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/terms-of-service'>
              <NavLink to='/terms-of-service'>
                Page List Generator
              </NavLink>
            </Menu.Item> */}
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      
      <Layout className='body-layout'>
        <Content className='content'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
};

export default Page;
