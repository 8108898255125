import { MinusOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Form, FormInstance, Input, Row, Table, Typography } from "antd";
import { ColumnsType } from 'antd/es/table';
import { useCallback, useState } from "react";
import { DisableListCharacterDto, DisableListItemBasicDto, DisableListTextSearchInput } from '../../api/client';
import { DisableListApi } from "../../api/clientFactory";
import { IConfigForm } from "./types";

interface IProps {
  cacheKey: number | undefined

  form: FormInstance<IConfigForm>

  sdl: DisableListCharacterDto[]
  setSdl: React.Dispatch<React.SetStateAction<DisableListCharacterDto[]>>
}

const SdlConfig = ({ cacheKey, form, sdl, setSdl } : IProps) => {
  const { Title, Paragraph } = Typography;
  const { TextArea } = Input;

  const [sdlOptions, setSdlOptions] = useState<{ value: number; label: string; data: DisableListCharacterDto }[]>([]);
  const [sdlNotFoundList, setSdlNotFoundList] = useState<String[]>([]);

  const sdlColumns: ColumnsType<DisableListCharacterDto> = [
    {
      title: '',
      key: 'action',
      width: 50,
      render: (_, record) => <Button shape="circle" size="small" onClick={() => { sdlRemove(record) }} icon={<MinusOutlined />} />
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Series',
      dataIndex: 'seriesName'
    }
  ];

  const sdlAdd = useCallback((item: DisableListCharacterDto) => {
    setSdl(pre => [...pre, item].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i).sort((a, b) => (a.name || '').localeCompare((b.name || ''))));
  }, [setSdl]);
  
  const sdlRemove = useCallback((item: DisableListCharacterDto) => {
    setSdl(pre => pre.filter(x => x.id !== item.id));
  }, [setSdl]);

  const handleSdlSearch = useCallback((value: string) => {
    if (value) {
      DisableListApi
        .searchCharacters(value, cacheKey)
        .then((response) => {
          setSdlOptions(response.map(x => { return { value: x.id || 0, label: `${x.name} - ${x.seriesName}`, data: x }}))
        });
    } else {
      setSdlOptions([]);
    }
  }, [setSdlOptions]);

  const handleSdlSelect = useCallback((value: string, option: { value: number; label: string; data: DisableListItemBasicDto }) => {
    sdlAdd(option.data)
    form.setFieldValue('sdlName', '')
    setSdlOptions([])
  }, [form, sdlAdd, setSdlOptions]);

  const handleSdlBulkSearch = useCallback(() => {
    let input = new DisableListTextSearchInput();
    input.cacheKey = cacheKey
    input.text = form.getFieldValue('sdlNames');
    
    setSdlNotFoundList([]);
    
    DisableListApi
      .bulkSearchCharacters(input)
      .then((response) => {
        let characters = response.characters || [];
        setSdl(pre => [...pre, ...characters].filter((v, i, a) => a.findIndex(x => x.id === v.id) === i).sort((a, b) => (a.name || '').localeCompare((b.name || ''))));
        form.setFieldValue('sdlNames', '')
        setSdlNotFoundList(response?.notFound || []);
      });
  }, [form, setSdl])

  return (
    <Row>
      <Col span={24}>
        <Title level={5}>
          Server Disablelist
        </Title>
      </Col>
      <Col span={24}>
        <Paragraph>
          *Only supports Character Main names
        </Paragraph>
      </Col>
      <Col span={24}>
        <Form.Item name='sdlName' label="Search">
          <AutoComplete
            onSearch={handleSdlSearch}
            onSelect={handleSdlSelect}
            options={sdlOptions}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name='sdlNames' label="Bulk Add">
          <TextArea rows={4} placeholder="Zero Two $ Rem $ Megumin ..."/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Button type="primary" icon={<SearchOutlined />} onClick={handleSdlBulkSearch}>
          Search
        </Button>
      </Col>
      {sdlNotFoundList.length > 0 && (
        <Col span={24}>
          {sdlNotFoundList.map((value, index) => 
            <Paragraph key={index} style={{color: 'red'}}>
              Character '{value}' not found!
            </Paragraph>
        )}
        </Col>
      )}
      <Col span={24} className="table-col">
        <Table columns={sdlColumns} className='compressed-table' dataSource={sdl} pagination={{ defaultPageSize: 10, showSizeChanger: false }} />
      </Col>
    </Row>
  )
};

export default SdlConfig;
