import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConfigProvider, theme } from "antd";
import Page from './page';
import Home from './pages/home';
import FormatList from './pages/formatList/formatList';
import Loots from './pages/loots/loots';
import Rolls from './pages/rolls/rolls';
import PageList from './pages/pageList/pageList';
import DisableList from './pages/disableList/disableList';
import DisableListGen from './pages/disableListGen/disableListGen';
import KakeraValue from './pages/kakeraValue/kakeraValue';
import PrivacyPolicy from './pages/privacyPolicy/privacyPolicy';
import TermsOfService from './pages/termsOfService/termsOfService';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const { darkAlgorithm } = theme;

  return (
    <HelmetProvider>
      <ConfigProvider 
        theme={{ 
          algorithm: darkAlgorithm,
          token: {
            colorPrimary: '#1677ff',
            colorBgLayout: '#0b0b0b'
          }
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Page />}>
              <Route index element={<Home />} />
              <Route path='/rolls/' element={<Rolls />} />
              <Route path='/loots/' element={<Loots />} />
              <Route path='/disable-list/' element={<DisableList />} />
              <Route path='/disable-list-gen/' element={<DisableListGen />} />
              <Route path='/kakera-value/' element={<KakeraValue />} />
              <Route path='/format-list/' element={<FormatList />} />
              <Route path='/page-list/' element={<PageList />} />
              <Route path='/privacy-policy/' element={<PrivacyPolicy />} />
              <Route path='/terms-of-service/' element={<TermsOfService />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
