import { Col, Form, Input, Row } from "antd";

const ResultRolls = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="ownedStarWishRolls" label="Owned Starwish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="unclaimedStarWishRolls" label="Unclaimed Starwish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="claimedStarWishRolls" label="Claimed Starwish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="ownedWishRolls" label="Owned Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="ownedNormalRolls" label="Owned Non-Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="unclaimedWishRolls" label="Unclaimed Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="unclaimedNormalRolls" label="Unclaimed Non-Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="claimedWishRolls" label="Claimed Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="claimedNormalRolls" label="Claimed Non-Wish Rolls">
          <Input bordered={false} readOnly/>
        </Form.Item>
      </Col>
    </Row>
  )
};

export default ResultRolls;
