import { Button, Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useState } from "react";

interface IProps {
  rows: number
  text: string
}

const ExportList = ({ rows, text } : IProps) => {
  const [isForceCopied, setIsForcedCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }

    setIsForcedCopied(true);
  }, [text, setIsForcedCopied]);
  
  return (
    <Col span={24} className='message-row'>
      <TextArea rows={rows} value={text} readOnly/>
      <Button 
        className='copy-button' 
        onClick={() => copyToClipboard()} 
        onMouseLeave={() => { setIsForcedCopied(false) }}
      >
        {isForceCopied  ? 'Copied' : 'Copy'}
      </Button>
    </Col>
  )
};

export default ExportList;
