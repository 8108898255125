import { FileTextOutlined, MinusOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Form, Input, Modal, Row, Table, Typography } from "antd";
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/es/table/interface";
import { useCallback, useState } from "react";
import { DisableListItemDto, DisableListItemType, DisableListRoulette } from '../../api/client';

interface IProps {
  title: string
  description?: string
  height: number
  items: DisableListItemDto[]
  roulette: DisableListRoulette | undefined
  isLoading: boolean
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<DisableListItemDto> | SorterResult<DisableListItemDto>[], extra: TableCurrentDataSource<DisableListItemDto>) => void;

  slotLimit?: number
  overlapLimit?: number
  waLimit?: number
  haLimit?: number
  wgLimit?: number
  hgLimit?: number

  onSearch?: (text: string) => void
  onBulkSearch?: (text: string) => void
  bulkSearchPlaceholder?: string
  bulkSearchNotFound?: string[]

  addItem?: (item: DisableListItemDto) => void
  removeItem?: (item: DisableListItemDto) => void
}

const TableItems = ({ title, description, height, items, roulette, isLoading, onChange, slotLimit, overlapLimit, waLimit, haLimit, wgLimit, hgLimit, onSearch, onBulkSearch, bulkSearchPlaceholder, bulkSearchNotFound, addItem, removeItem } : IProps) => {
  const { Panel } = Collapse;
  const { Title, Paragraph } = Typography;
  const { Search, TextArea } = Input;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [detailsItem, setDetailsItem] = useState<DisableListItemDto>(new DisableListItemDto());

  const [bulkSearchForm] = Form.useForm<{text: string}>();

  const openDetails = useCallback((item: DisableListItemDto) => {
    setDetailsItem(item);
    setIsDetailsOpen(true);
  }, [setIsDetailsOpen, setDetailsItem])

  const closeDetails = useCallback(() => {
    setIsDetailsOpen(false);
  }, [setIsDetailsOpen])

  const columns: ColumnsType<DisableListItemDto> = [
    {
      title: '',
      width: 35,
      render: (_, record) => 
        <span>
          {addItem && (
            <Button shape="circle" size="small" onClick={() => { addItem(record) }} icon={<PlusOutlined />} />
          )}
          {removeItem && (
            <Button shape="circle" size="small" onClick={() => { removeItem(record) }} icon={<MinusOutlined />} />
          )}
        </span>
    },
    {
      title: 'Name',
      render: (_, record) =>
        <span className={slotLimit != undefined && slotLimit < 1 ? "overlimit-error" : ""}>
          {record.name}
        </span>
    },
    {
      key: 'size',
      title: 'Size',
      width: 55,
      sortDirections: onChange ? ['ascend', 'descend'] : [],
      sorter: (a, b) => 0,
      render: (_, record) =>
        <span className={overlapLimit != undefined && overlapLimit < (record.size || 0) ? "overlimit-error" : ""}>
          {record.size}
        </span>
    },
    {
      key: 'actual',
      title: 
        roulette === DisableListRoulette.Wa ? '$wa' :
        roulette === DisableListRoulette.Ha ? '$ha' :
        roulette === DisableListRoulette.Wg ? '$wg' :
        roulette === DisableListRoulette.Hg ? '$hg' :
        '$',
      width: 55,
      sortDirections: onChange ? ['ascend', 'descend'] : [],
      sorter: (a, b) => 0,
      render: (_, record) => {
        let limit: number | undefined = undefined;
        let actual: number = 0;

        switch (roulette) {
          case DisableListRoulette.Wa:
            limit = waLimit;
            actual = record.actualWa || 0;
            break;
          case DisableListRoulette.Ha:
            limit = haLimit;
            actual = record.actualHa || 0;
            break;
          case DisableListRoulette.Wg:
            limit = wgLimit;
            actual = record.actualWg || 0;
            break;
          case DisableListRoulette.Hg:
            limit = hgLimit;
            actual = record.actualHg || 0;
            break;
          default:
            limit = 0;
            actual = 0;
            break;
        }

        return (
          <span className={limit != undefined && limit < (actual || 0) ? "overlimit-warn" : ""}>
            {actual}
          </span>
        )
      }
    },
    {
      key: 'percent',
      title: 
        roulette === DisableListRoulette.Wa ? '%wa' :
        roulette === DisableListRoulette.Ha ? '%ha' :
        roulette === DisableListRoulette.Wg ? '%wg' :
        roulette === DisableListRoulette.Hg ? '%hg' :
        '%',
      width: 55,
      sortDirections: onChange ? ['ascend', 'descend'] : [],
      sorter: (a, b) => 0,
      render: (_, record) => { 
        let actual: number = 0;

        switch (roulette) {
          case DisableListRoulette.Wa:
            actual = record.actualWa || 0;
            break;
          case DisableListRoulette.Ha:
            actual = record.actualHa || 0;
            break;
          case DisableListRoulette.Wg:
            actual = record.actualWg || 0;
            break;
          case DisableListRoulette.Hg:
            actual = record.actualHg || 0;
            break;
          default:
            actual = 0;
            break;
        }

        return `${parseFloat((actual / (record.size || 1) * 100).toFixed(1))}%`
      }
    },
    {
      title: '',
      width: 35,
      render: (_, record) => <Button shape="circle" size="small" onClick={() => { openDetails(record) }} icon={<FileTextOutlined />} />
    },
  ];

  return (
    <div>
      <Collapse bordered={false} className='table-collapse' defaultActiveKey={['1']}>
        <Panel header={title} key='1'>
          <Row>
            {description && (
              <Col span={24}>
                <Paragraph>
                  {description}
                </Paragraph>
              </Col>
            )}
            {onSearch && (
              <Col span={24}>
                <Input
                  className="search-box"
                  placeholder="Search..."
                  onChange={e => onSearch(e.target.value)}
                />
              </Col>
            )}

            <Col span={24} className='table-col'>
              <Table 
                columns={columns} 
                className='compressed-table'
                dataSource={items} 
                pagination={{ defaultPageSize: 10, showSizeChanger: false }}
                loading={isLoading}
                onChange={onChange}/>
            </Col>
          </Row>
          {onBulkSearch && (
            <Form layout="vertical" form={bulkSearchForm}>
              <Row>
                <Col span={24}>
                  <Form.Item name='text' label="Bulk Add">
                    <TextArea rows={4} placeholder={bulkSearchPlaceholder}/>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button type="primary" icon={<SearchOutlined />} loading={isLoading} onClick={(() => { onBulkSearch(bulkSearchForm.getFieldValue('text')); bulkSearchForm.setFieldValue('text', '') })}>
                    Search
                  </Button>
                </Col>
                {bulkSearchNotFound && bulkSearchNotFound.length > 0 && (
                  <Col span={24}>
                    {bulkSearchNotFound.map((value, index) => 
                      <Paragraph key={index} style={{color: 'red'}}>
                        Bundle or Series '{value}' not found!
                      </Paragraph>
                  )}
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Panel>
      </Collapse>

      <Modal 
        title={<Title level={3}>{detailsItem.name}</Title>}
        open={isDetailsOpen}
        onCancel={closeDetails}
        footer={[
          <Button key="close" onClick={closeDetails}>
            Close
          </Button>
        ]}>
        <Paragraph>Type: {DisableListItemType[detailsItem?.type == undefined ? DisableListItemType.Bundle : detailsItem?.type]}</Paragraph>
        <Paragraph>Size: {detailsItem.size} ($wa: {detailsItem.totalWa}, $ha: {detailsItem.totalHa}, $wg: {detailsItem.totalWg}, $hg: {detailsItem.totalHg})</Paragraph>
        <Title level={4}>
          Breakdown
        </Title>

        <Title level={5}>
          $wa - Effective: {detailsItem.actualWa}
        </Title>
        <Paragraph>Sdl: {detailsItem.serverDisabledWa}, Adl: {detailsItem.antiDisabledWa}, Toggle: {detailsItem.toggleDisabledWa}, Overlap: {detailsItem.overlapDisabledWa}</Paragraph>
        
        <Title level={5}>
          $ha - Effective: {detailsItem.actualHa}
        </Title>
        <Paragraph>Sdl: {detailsItem.serverDisabledHa}, Adl: {detailsItem.antiDisabledHa}, Toggle: {detailsItem.toggleDisabledHa}, Overlap: {detailsItem.overlapDisabledHa}</Paragraph>
        
        <Title level={5}>
          $wg - Effective: {detailsItem.actualWg}
        </Title>
        <Paragraph>Sdl: {detailsItem.serverDisabledWg}, Adl: {detailsItem.antiDisabledWg}, Toggle: {detailsItem.toggleDisabledWg}, Overlap: {detailsItem.overlapDisabledWg}</Paragraph>
        
        <Title level={5}>
          $hg - Effective: {detailsItem.actualHg}
        </Title>
        <Paragraph>Sdl: {detailsItem.serverDisabledHg}, Adl: {detailsItem.antiDisabledHg}, Toggle: {detailsItem.toggleDisabledHg}, Overlap: {detailsItem.overlapDisabledHg}</Paragraph>
      </Modal>
    </div>
  )
};

export default TableItems;
