import './rolls.scss';
import '../shared.scss';

import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Select, Tooltip, Typography } from 'antd';
import Collapse from 'antd/es/collapse';
import { useCallback, useEffect, useState } from 'react';
import { BoostWishInput, BoostWishCalculateResult, BoostWishOptimizationMode, BoostWishOptimizeResult } from '../../api/client';
import { BoostWishApi } from '../../api/clientFactory';
import InputRolls from './inputRolls';
import { Mode } from './types';
import InputBonuses from './inputBonuses';
import InputRoulette from './inputRoulette';
import ResultRolls from './resultRows';
import ResultKeys from './resultKeys';
import Seo from '../shared/seo';

interface IModeForm {
  mode: Mode,
}

function Rolls() {
  const { Title, Paragraph } = Typography;
  const { Panel } = Collapse;
  const { Option } = Select;

  const [modeForm] = Form.useForm<IModeForm>();
  const [form] = Form.useForm<BoostWishInput>();
  const [calculateResultForm] = Form.useForm<BoostWishCalculateResult>();
  const [optimizeResultForm] = Form.useForm<BoostWishOptimizeResult>();
  
  const [mode, setMode] = useState(Mode.Calculate);
  const [resultMode, setResultMode] = useState(Mode.Calculate);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    modeForm.setFieldValue('mode', Mode.Calculate);
  }, [modeForm]);

  useEffect(() => {
    form.setFieldValue('optimizationMode', BoostWishOptimizationMode.WishKeys);
  }, [form]);
  
  const handleModeChanged = useCallback((e: RadioChangeEvent) => {
    setMode(e.target.value);
  }, [setMode]);

  const calculate = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        BoostWishApi
          .calculate(values)
          .then((response) => {
            setResultMode(mode);
            calculateResultForm.setFieldsValue(response);
            setShowResult(true);
          });
      });
  }, [BoostWishApi, form, mode, setResultMode, calculateResultForm, setShowResult]);

  const optimize = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        BoostWishApi
          .optimize(values)
          .then((response) => {
            setResultMode(mode);
            optimizeResultForm.setFieldsValue(response);
            setShowResult(true);
          });
      });
  }, [BoostWishApi, form, mode, setResultMode, optimizeResultForm, setShowResult]);

  return (
    <div className='rolls'>
      <Seo title='Mudae Roll Calculator' description='Calculate expected Mudae roll outcomes or optimize boost wish (bw) values'/>
      <Title>
        Roll Outcome Calculator
      </Title>

      <Title level={2}>
        Modes
      </Title>
      <Paragraph>
        <ul>
          <li>
            Calcluate: Calculate the expected outcome of your rolls
          </li>
          <li>
            Optimize: Calulate your optimal boostwish value
          </li>
        </ul>
      </Paragraph>
      
      <Title level={2}>
        Notes
      </Title>
      <Paragraph>
        <ul>
          <li>
            When filling out the Roulette section please remember the following definitions:
            <ul>
              <li>Owned: A character that is in your harem</li>
              <li>Claimed: A character that is in another player's harem</li>
              <li>Unclaimed: A character that has not been claimed yet</li>
            </ul>
          </li>
          <li>
            Exact values for the Roulette section may be hard to determine, please use the most accurate values you can provide instead
          </li>
        </ul>
      </Paragraph>

      <Form form={modeForm} layout="vertical">
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item name="mode" label="Mode">
              <Radio.Group onChange={handleModeChanged} style={{width: '100%'}}>
                <Radio.Button value={Mode.Calculate} style={{width: '50%'}}>Calculate</Radio.Button>
                <Radio.Button value={Mode.Optimize} style={{width: '50%'}}>Optimize</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Form form={form} layout="vertical">
        <Row gutter={10}>
          {mode == Mode.Optimize && (
            <Col span={24}>
              <Form.Item 
                name="optimizationMode" 
                label={
                  <Tooltip title="Goal for optimizing $bw">
                    <span>Optimize for</span>
                  </Tooltip>
                }
                rules={[{ required: true, message: "Optimize for is required" }]}>
                <Select>
                  <Option value={BoostWishOptimizationMode.WishKeys}>Wish Keys</Option>
                  <Option value={BoostWishOptimizationMode.AllKeys}>All Keys</Option>
                  <Option value={BoostWishOptimizationMode.UnclaimedWish}>Unclaimed Wish</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Rolls" key="1" forceRender>
            <InputRolls mode={mode}/>
          </Panel>

          <Panel header="Bonuses" key="2" forceRender>
            <InputBonuses mode={mode}/>
          </Panel>

          <Panel header="Roulette" key="3" forceRender>
            <InputRoulette form={form}/>
          </Panel>
        </Collapse>
          
        <Row className='button-row'>
          {mode == 0 && (
            <Col>
              <Button onClick={calculate}>
                Calculate
              </Button>
            </Col>
          )}
          {mode == 1 && (
            <Col>
              <Button onClick={optimize}>
                Optimize
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      {showResult && (
        <div className='result-box'>
          <Title level={2}>Summary</Title>
          
          {resultMode == Mode.Calculate && (
            <Form form={calculateResultForm}>
              <Row>
                <Col span={24}>
                  <Form.Item name="hourlyRolls" label="Hourly Rolls">
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </Row>

              <ResultRolls />
            </Form>
          )}
          {resultMode == Mode.Optimize && (
            <Form form={optimizeResultForm}>
              <Row>
                <Col span={12}>
                  <Form.Item name="boostWishRolls" label="Suggested Boost Wish Rolls">
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="hourlyRolls" label="Remaining Hourly Rolls">
                    <Input bordered={false} readOnly/>
                  </Form.Item>
                </Col>
              </Row>

              <Title level={3}>Roll Breakdown</Title>
              <ResultRolls />
              
              <Title level={3}>Key Breakdown</Title>
              <ResultKeys />
            </Form>
          )}
        </div>
      )}
    </div>
  );
}

export default Rolls;
